<template>
  <div>
    <Divider dashed style="margin: 5px 0;">
      <span class="divider-text">审批流程配置</span>
    </Divider>

    <Form>
        <FormItem label="审批人配置">
            <br/>
            <Row v-for="(node,i) in nodes" :key="i">
                <i-col class="p-t-5" span="24">
                    <new-node
                    :id="node.id"
                    :editable="false"
                    :can-delete="true"
                    :name="node.name?node.name:('第'+(i+1)+'步')"
                    :users="node.users"
                    :publisherId="publisherId"
                    :companyId="companyId"
                    @removeUser="handleGroupUserRemove"
                    @addUser="handleAddUser"
                    @deleteNode="deleteNode"
                    ></new-node>
                </i-col>
            </Row>
            <p><a @click="addNode"><Icon type="md-add" />点击添加节点</a></p>
        </FormItem>

        <FormItem label="抄送人配置">
          <br/>
            <Row :gutter="16" >
                <i-col span="24" v-for="(send,i) in sendPerson" :key="i"  class="p-t-5">
                  <new-node
                      :id="send.id"
                      :editable="false"
                      :can-delete="false"
                      :name="'审批完成后通知人员'"
                      :users="send.users"
                      :publisherId="publisherId"
                      :companyId="companyId"
                      @removeUser="handleGroupSendUserRemove"
                      @addUser="handleAddSendUser"
                      ></new-node>
                </i-col>
            </Row>
        </FormItem>

      </Form>

  </div>
</template>

<script>
import {
  getDefaultApprovalNodeList
} from '@/api/approval/processconfig'
export default {
  components: {
    NewNode: () => import('@/components/setting/workflow/NewNode')
  },
  props: {
    processtype: {
      type: Number
    },
    publisherId: {
      type: Number
    },
    companyId: {
      type: Number
    },
    relateId: {
      type: Number
    }
  },
  data () {
    return {
      nodes: [],
      sendPerson: []
    }
  },
  created () {
    this.initDefaultApprovalNode()
  },
  methods: {
    initDefaultApprovalNode () {
      this.typeName = this.name
      const query = {
        companyId: this.companyId,
        publisherId: this.publisherId,
        processtype: this.processtype
      }
      if (this.relateId) {
        query.relateId = this.relateId
      }
      getDefaultApprovalNodeList(query).then((data) => {
        this.nodes = data.approvalnodeAndUsersList.map((item) => {
          return {
            id: item.approvalnode.id,
            name: item.approvalnode.name,
            users: item.users || []
          }
        })
        this.sendPerson = [{
          id: 999,
          users: data.ccUserList
        }]
        this.reloadUserGroup()
      })
    },

    // 增加节点
    addNode () {
      const length = this.nodes.length
      this.nodes.push({
        name: '',
        users: [],
        id: length + 1
      })
    },
    // 删除节点
    deleteNode (nodeId) {
      const index = this.nodes.findIndex((item) => item.id === nodeId)
      this.nodes.splice(index, 1)
      this.reloadUserGroup()
    },
    // 增加节点用户(审批人)
    handleAddUser (nodeId, user) {
      this.nodes.find((item) => item.id === nodeId).users.push(user)
      this.reloadUserGroup()
    },
    // 移除节点用户(审批人)
    handleGroupUserRemove (nodeId, userIndex) {
      const node = this.nodes.find((item) => item.id === nodeId)
      node.users.splice(userIndex, 1)
      this.reloadUserGroup()
    },

    // 增加节点用户(抄送人)
    handleAddSendUser (nodeId, user) {
      this.sendPerson.find((item) => item.id === nodeId).users.push(user)
      this.reloadUserGroup()
    },
    // 移除节点用户(抄送人)
    handleGroupSendUserRemove (nodeId, userIndex) {
      const node = this.sendPerson.find((item) => item.id === nodeId)
      node.users.splice(userIndex, 1)
      this.reloadUserGroup()
    },
    // 更新实际配置人员信息
    reloadUserGroup () {
      var approvalUsers = []
      var sendUsers = []
      this.nodes.forEach(node => {
        if (node.users.length) {
          approvalUsers.push(node.users.map(user => { return user.id }))
        }
      })
      this.sendPerson.forEach(node => {
        if (node.users.length) {
          node.users.forEach(user => {
            sendUsers.push(user.id)
          })
        }
      })
      this.$emit('reloadUserGroup', approvalUsers, sendUsers)
    }
  },
  watch: {
    processtype (val) {
      this.initDefaultApprovalNode()
    },
    relateId () {
      this.initDefaultApprovalNode()
    }
  }
}
</script>
