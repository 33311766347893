<template>
    <div>
        <Divider dashed style="margin: 5px 0;">
          <span class="divider-text">基础信息配置</span>
        </Divider>
        <Form ref="formValidate" :model="approvalBean" :rules="ruleValidate" >
            <FormItem label="审批名称" prop="name">
                <Input v-model="approvalBean.name" maxlength="50" size="small" placeholder="请输入审批内容的名称..."  />
            </FormItem>

            <FormItem label="上传内容描述" prop="content">
                <Input v-model="approvalBean.content" type="textarea"  class="p-t-5"   maxlength="500" :autosize="{minRows: 4,maxRows: 6}" placeholder="请输入上传内容描述，不能少于10个字符"/>
            </FormItem>

            <FormItem >
                <Upload multiple type="drag" :before-upload="handleBeforeUploadFile" action="">
                    <div >
                        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                        <p>点击或拖拽文件到这里进行上传</p>
                    </div>
                </Upload>
                <Row v-show="fileList.length">
                    <i-col span="14"><b>文件名称</b></i-col>
                    <i-col span="5"><b>文件大小</b></i-col>
                    <i-col span="5"><b>操作</b></i-col>
                </Row>
                <hr v-show="fileList.length">
                <Row v-for="(file,fileIndex) in fileList" :key="fileIndex"  v-show="fileList.length">
                    <i-col span="14">{{file.name}}</i-col>
                    <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col>
                    <i-col span="5"><a href="javascript:;" class="text-red" @click="handleRemove(file)">删除</a></i-col>
                    <i-col span="24"><hr></i-col>
                </Row>
            </FormItem>

            <Row v-if="detailId">
              <i-col span="24">
                <h4 slot="title">历史上传文件清单</h4>
                <i-table  stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
              </i-col>

            </Row>

            <specialComponent ref="procedureSetting" :relateId="detailId" :publisherId="publisherId" :companyId="companyId"  :processtype="13" @reloadUserGroup="reloadUserGroup"/>
            <!-- <FormItem label="审批流程" prop="approvalUsers">
             <p v-for="proc in procedures" :key="proc.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedProc(proc.id)"
                      :class="currentprocedureId===proc.id?'workplatform-radio-button-active':'workplatform-radio-button'">{{proc.name}}</p>

            </FormItem>
             <FormItem label="抄送人（审批通过后通知人员）" prop="content">
                <Input v-model="approvalBean.content" type="textarea"  maxlength="500" :autosize="{minRows: 4,maxRows: 6}" placeholder="请输入上传内容描述..."/>
            </FormItem> -->

            <FormItem class="text-right">
              <Button type="info" class="m-r-10" size="small" @click="handleSubmit('formValidate', true)">保存为草稿</Button>
              <Button type="success" size="small" @click="handleSubmit('formValidate', false)">提交审批</Button>
            </FormItem>
        </Form>

        <Modal v-model="beginUpload" width="400" :styles="{top: '250px'}" :mask-closable="false" :closable="false" footer-hide>
          <h3 class="text-center">上传文件中..</h3>
          <Progress :percent="percentage" :stroke-width="20" status="active" text-inside></Progress>
        </Modal>
    </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { downloadFileRequest } from '@/utils/download'
import { getApprovalById, fileByApprovalId } from '@/api/sys/approval'
import specialComponent from '@/components/approvalCreate/components/ProcedureSetting'

export default {
  components: {
    specialComponent
  },
  data () {
    return {
      approvalBean: {
        name: '',
        content: '',
        approvalUsers: [],
        sendUsers: []
      },
      ruleValidate: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        content: [
          { required: true, message: ' ', trigger: 'blur' },
          { type: 'string', min: 10, message: ' ', trigger: 'blur' }
        ],
        approvalUsers: [
          { required: true, message: '请配置审批人员' }
        ]
      },
      fileList: [],
      percentage: 0, // 文件上传进度
      beginUpload: false, // 开始执行上传
      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '10px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              }, '下载'),
              h('a', {
                on: {
                  click: () => {
                    this.deleteFile(params.row)
                  }
                }
              }, '移除')
            ])
          }
        }
      ],
      deleteFileIds: [],
      approvalUsers: [],
      sendUsers: []
      // procedures: [
      //   { id: 1, name: '默认流程' },

      //   { id: 2, name: '自定义流程' }
      // ],
      // currentprocedureId: 1
    }
  },
  methods: {
    changeCheckedProc (val) {
      this.currentprocedureId = val
    },
    initPageData () {
      // 验证是否为编辑界面

      if (this.detailId) {
        const query = { approvalId: this.detailId }
        getApprovalById(query).then(res => {
          this.approvalBean.name = res.name
          this.approvalBean.content = res.content
        })

        fileByApprovalId(query).then(res => {
          this.approvalFile = res
        })
      } else {
        this.$refs.formValidate.resetFields()
        this.sendUsers = []
        this.approvalUsers = []
        this.fileList = []
      }
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    reloadUserGroup (approvalUsers, sendUsers) {
      this.approvalUsers = approvalUsers
      this.sendUsers = sendUsers
    },
    handleSubmit (name, isDraft) {
      const that = this
      if (that.approvalUsers.length === 0) {
        that.$Notice.info({ desc: '请先配置审批人员！' })
        return
      }
      that.$refs[name].validate((valid) => {
        if (valid) {
          that.beginUpload = true
          that.percentage = 0
          // 开始批量执行上传操作
          const uploadData = new FormData()
          uploadData.append('name', that.approvalBean.name)
          uploadData.append('content', that.approvalBean.content)
          uploadData.append('approvalUserIdsStr', JSON.stringify(that.approvalUsers))
          uploadData.append('draft', isDraft)

          if (that.sendUsers.length) {
            uploadData.append('ccUserIdStr', JSON.stringify(that.sendUsers))
          }
          that.fileList.forEach(function (file, index) {
            uploadData.append('files', file)
          })

          if (that.detailId) { // 执行编辑操作
            uploadData.append('approvalId', that.detailId)
            uploadData.append('deleteFileIds', JSON.stringify(that.deleteFileIds))
          }

          axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/approval/cratefileapproval',
            uploadData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'ooh-auth': getStorage('ooh-token')
              },
              withCredentials: true,
              onUploadProgress (progress) { // 处理上传文件进度条数据
                that.percentage = Math.round(progress.loaded / progress.total * 100)
              }
            }
          ).then(res => {
            that.beginUpload = false
            if (res && res.data && !res.data.errcode) {
              that.$Notice.success({ desc: '提交审批成功' })
              that.$refs[name].resetFields()
              that.fileList = []
              if (that.detailId) {
                that.$store.commit('set_approvalCreate_detailId', null)
              } else {
                that.$refs.procedureSetting.initDefaultApprovalNode()
              }
              that.$store.commit('set_approvalCreate_update', new Date())
            } else {
              that.$Notice.error({ desc: res.data.errmsg })
            }
          })
        }
      })
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/approval/downloadfile', { fileId: file.id }, file.fileName)
    },
    deleteFile (file) {
      const fileIndex = this.approvalFile.findIndex(x => x.id === file.id)
      this.approvalFile.splice(fileIndex, 1)
      this.deleteFileIds.push(file.id)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  },
  computed: {
    detailId () {
      return this.$store.state.approvalCreate.detailId
    },
    companyId () {
      return this.$store.state.approvalCreate.companyId
    },
    publisherId () {
      return this.$store.state.approvalCreate.publisherId
    }
  },
  watch: {
    detailId (val) {
      this.initPageData()
    }
  }
}
</script>
